import * as AllIcons from "phosphor-react";

export function GetIcon(icon: string) {
  try {
    const Icon: any = AllIcons[icon as keyof typeof AllIcons];
    return Icon;
  } catch {
    return AllIcons.Warning;
  }
}
