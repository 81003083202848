import React, { useContext } from "react";
import Private from "./Private";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { MasterLayout } from "../components/_metronic/layout/MasterLayout";
import { useSelector } from "react-redux";

//PAGES
import Login from "../pages/Auth/Login";
import Recover from "../pages/Auth/Recover";
import FeedbackWithoutLogin from "../pages/Evaluations/FeedbackConfirmation/FeedbackWithoutLogin";
import TrainingForms from "../pages/Trainings/List/TrainingForms/TrainingsForms";
import Error404 from "../components/Errors/404";

const PublicRoutes: React.FC = () => {
  const user = useSelector((state: any) => state.user);
  const company = useSelector((state: any) => state.company);

  return (
    <BrowserRouter>
      {company.dominio ? (
        <Routes>
          <Route path="/feedback/:hash" element={<FeedbackWithoutLogin />} />
          <Route path="/training-form/:hash" element={<TrainingForms />} />
          {user.id ? (
            <Route element={<MasterLayout />}>
              <Route path="*" element={<Private />} />
            </Route>
          ) : (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="/recover" element={<Recover />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
        </Routes>
      ) : (
        <Routes>
          <Route
            path="404"
            element={
              <div className="not-found-page">
                <Error404>
                  <p className="fs-2">Empresa não encontrada</p>
                </Error404>
              </div>
            }
          />
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      )}
    </BrowserRouter>
  );
};

export default PublicRoutes;
