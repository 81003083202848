import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";
import { ToastContainer } from "react-toastify";

//STYLING
import "react-toastify/dist/ReactToastify.css";
import "./assets/index.css";
import "./assets/boxmodel.css";

//METRONIC
import { MetronicI18nProvider } from "./components/_metronic/i18n/Metronici18n";
import "./components/_metronic/assets/sass/style.scss";
import "./components/_metronic/assets/sass/plugins.scss";
import "./components/_metronic/assets/sass/style.react.scss";
import { I18nProvider } from "./components/_metronic/i18n/i18nProvider";
import { LayoutProvider } from "./components/_metronic/layout/core/LayoutProvider";
import { Outlet } from "react-router-dom";
import { MasterInit } from "./components/_metronic/layout/MasterInit";

const root = ReactDOM.createRoot(document.getElementById("root")!);

root.render(
  <MetronicI18nProvider>
    <I18nProvider>
      <LayoutProvider>
        <Provider store={store}>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
          />
          <Outlet />
          <MasterInit />
          <App />
        </Provider>
      </LayoutProvider>
    </I18nProvider>
  </MetronicI18nProvider>
);
