import clsx from "clsx";
import classes from "./Auth.module.css";
import Input from "../../components/UI/Input/Input";
import { useState } from "react";
import { postLogin } from "../../axios/requests/auth";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../../components/UI/LoadingButton/LoadingButton";
import { userActions } from "../../store/user";
import { toAbsoluteUrl } from "../../components/_metronic/helpers";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";

const Login: React.FC = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const company = useSelector((state: any) => state.company);

  const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

  const [login, setLogin] = useState<any>({});

  const [error, setError] = useState<any>(null);

  function updateState(value: any, param: any) {
    let copy = { ...login };
    copy[param] = value;
    setLogin(copy);
  }

  function submitLogin() {
    setIsSubmiting(true);
    postLogin(login)
      .then((res: any) => {
        localStorage.setItem("token", res.data.access_token);
        dispatch(userActions.login(res.data));
      })
      .catch((err: any) => {
        setIsSubmiting(false);
        if (err.response.status === 401) {
          setError("LOGIN_ERROR_1");
        } else {
          setError("LOGIN_ERROR_2");
        }
      });
  }

  return (
    <div className={"col-12 d-flex flex-wrap"}>
      <div
        className={clsx(classes.left, "col-12 col-md-4 p-6")}
        style={{
          backgroundImage: `url("../../media/clients/${company.dominio}/login/bg.jpg")`,
        }}
      >
        <div className={classes.titles}>
          <h1>{company.titulo_login_1}</h1>
          <h2>{company.titulo_login_2}</h2>
        </div>
      </div>
      <div className={clsx(classes.right, "col-12 col-md-8")}>
        <form
          onSubmit={(e: any) => {
            e.preventDefault();
            submitLogin();
          }}
          className={clsx(classes.form, "col-12")}
        >
          <div className="col-8 col-sm-7 col-md-6 col-lg-6 col-xl-4">
            <div className="text-center mb-10">
              <img
                draggable={false}
                src={toAbsoluteUrl(
                  `/media/clients/${company.dominio}/logo/logo.png`
                )}
                className={`${classes.logo}`}
              />
              <h1 className="text-dark mb-3 mt-6">Inicie Sessão</h1>
              <h4 className="opacity-50">Introduza as credenciais de acesso</h4>
            </div>

            {error ? (
              <p className="bg-danger fs-6 fw-bold text-white p-4 rounded mb-6">
                {intl.formatMessage({ id: error })}
              </p>
            ) : null}
            <Input
              title="Email ou Telemóvel"
              placeholder="Email ou Telemóvel"
              type={"text"}
              state={login.username}
              stateUpdate={updateState}
              param="username"
              space="mb-5"
              topTag
            />
            <Input
              title="Palavra-passe"
              placeholder="Palavra-passe"
              type={"password"}
              state={login.password}
              stateUpdate={updateState}
              param="password"
              space="mb-5"
              topTag
            />
            <div className="d-flex justify-content-between mt-n5">
              <div className="d-flex justify-content-end mt-2 col-12">
                <Link to="/recover" className="fs-6 fw-bolder text-primary">
                  Esqueceu-se?
                </Link>
              </div>
            </div>
            <LoadingButton
              title="Iniciar sessão"
              size="col-12 mt-5"
              loading={isSubmiting}
              disabled={!login.username || !login.password}
              submit
            />
          </div>
        </form>
      </div>

      <a className={clsx(classes.footer, "p-6")} href="https://frejen.pt/">
        Desenvolvido por Frejen - Wise Solutions
      </a>
    </div>
  );
};

export default Login;
