import clsx from "clsx";
import { useState } from "react";
import ReactSelect, { StylesConfig } from "react-select";

type Props = {
  options: any;
  state: any;
  stateUpdate: any;
  serverState?: boolean;
  title?: string;
  serverSearch?: boolean;
  search?: any;
  searchUpdate?: any;
  disabled?: boolean;
  param?: any;
  size?: any;
  space?: any;
  topTag?: boolean;
  isMulti?: boolean;
  loading?: boolean;
  clearable?: boolean;
  noOptionsMessage?: any;
  labelSize?: string;
  portal?: any;
  placeholder?: string;
  required?: any;
};

const Select: React.FC<Props> = ({
  title,
  serverSearch,
  search,
  searchUpdate,
  disabled,
  options,
  state,
  stateUpdate,
  param,
  serverState,
  size,
  topTag,
  space,
  isMulti,
  loading,
  clearable,
  noOptionsMessage,
  labelSize,
  portal,
  placeholder,
  required,
}) => {
  const [localSearch, setLocalSearch] = useState("");

  const formatGroupLabel = (data: any) => (
    <div className="col-12 col-form-label">
      <span>{data.label}</span>
    </div>
  );

  const tick = {
    display: "flex",
    alignItems: "center",
    width: "100%",

    ":after": {
      color: "green",
      content: '"✓"',
      display: "block",
      fontWeight: "800",
      marginLeft: "10px",
    },
  };

  const customStyles: StylesConfig = {
    option: (styles, { data, isSelected }) => {
      const toReturn = {
        ...styles,
        backgroundColor: isSelected && !isMulti ? "var(--primary)" : "",
        color: isSelected && !isMulti ? "#fff" : "",
        "&:hover": {
          backgroundColor: "var(--primary)",
          color: "#fff",
          opacity: "80%",
        },
      };
      if (isSelected && isMulti) {
        return {
          ...toReturn,
          ...tick,
        };
      } else {
        return toReturn;
      }
    },
  };

  function getValue() {
    let optionsCopy: any = [...options];

    for (let i = 0; i < optionsCopy.length; i++) {
      if (optionsCopy[i].options && optionsCopy[i].options.length) {
        optionsCopy[i].options.forEach((option: any) => {
          optionsCopy.push(option);
        });
      }
    }

    if (state) {
      if (isMulti) {
        if (state.length) {
          let values: any = [];
          state.forEach((item: any) => {
            let valueCopy = optionsCopy.find((obj: any) => obj.value == item);
            if (valueCopy) {
              values.push(valueCopy);
            }
          });
          return values;
        } else {
          return "";
        }
      } else {
        if (optionsCopy && optionsCopy.length) {
          return optionsCopy.find((obj: any) => obj.value == state);
        } else {
          return "";
        }
      }
    } else {
      return isMulti ? [] : "";
    }
  }

  return (
    <div
      className={clsx(
        !title && topTag ? "d-flex align-items-center" : null,
        space,
        size
      )}
    >
      {title ? (
        <label
          className={clsx(
            topTag && "col-12",
            !topTag && "mr-3",
            "col-form-label",
            labelSize,
            required && "required"
          )}
        >
          {title}
        </label>
      ) : null}
      <ReactSelect
        options={options}
        value={serverState ? state : getValue()}
        styles={customStyles}
        onChange={(selected: any) => {
          if (serverState) {
            if (param != null) {
              stateUpdate(selected, param);
            } else {
              stateUpdate(selected);
            }
          } else {
            if (isMulti) {
              if (param != null) {
                stateUpdate(
                  selected
                    ? selected.map((obj: any) => {
                        return obj.value;
                      })
                    : null,
                  param
                );
              } else {
                stateUpdate(
                  selected
                    ? selected.map((obj: any) => {
                        return obj.value;
                      })
                    : null
                );
              }
            } else {
              if (param != null) {
                stateUpdate(selected ? selected.value : null, param);
              } else {
                stateUpdate(selected ? selected.value : null);
              }
            }
          }
        }}
        isMulti={isMulti}
        filterOption={(value: any) => {
          if (serverSearch || typeof value.label != "string") {
            return true;
          } else {
            let compare = localSearch
              .toLowerCase()
              .split(" ")
              .filter((obj: any) => obj.length);
            if (!compare.length) {
              return true;
            } else {
              let match = false;
              compare.every((string: any) => {
                if (value && value.label.toLowerCase().includes(string)) {
                  match = true;
                  return false;
                } else {
                  return true;
                }
              });
              return match;
            }
          }
        }}
        inputValue={serverSearch ? search : localSearch}
        onInputChange={(value) => {
          if (searchUpdate) {
            searchUpdate(value);
          }
          setLocalSearch(value);
        }}
        isLoading={loading}
        placeholder={placeholder ? placeholder : "Digite para pesquisar..."}
        isDisabled={disabled}
        isOptionDisabled={(option: any) => option.disabled}
        isClearable={clearable}
        noOptionsMessage={noOptionsMessage}
        formatGroupLabel={formatGroupLabel}
        menuPortalTarget={portal ? portal : null}
        hideSelectedOptions={false}
        closeMenuOnSelect={!isMulti}
      />
      {required ? (
        <input
          style={{
            border: "none",
            outline: "none",
            position: "absolute",
            zIndex: "-1",
            marginTop: "-35px",
            caretColor: "transparent",
          }}
          required
          value={state ? " " : ""}
          onChange={() => {}}
          disabled={disabled}
        />
      ) : null}
    </div>
  );
};
export default Select;
