import { FC, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useLayout } from "../../core";
import { toAbsoluteUrl } from "../../../helpers";
import { AsideMenu } from "./AsideMenu";
import { useSelector } from "react-redux";
import { CaretDoubleRight, CaretRight } from "phosphor-react";

const AsideDefault: FC = () => {
  const { config, classes, hideLateralMenu } = useLayout();
  const asideRef = useRef<HTMLDivElement | null>(null);
  const user = useSelector((state: any) => state.user);
  const company = useSelector((state: any) => state.company);

  const minimize = () => {
    asideRef.current?.classList.add("animating");
    setTimeout(() => {
      asideRef.current?.classList.remove("animating");
    }, 300);
  };

  useEffect(() => {
    hideLateralMenu(user.hide_lateral_menu == 1 ? true : false);
    minimize();
  }, [user.hide_lateral_menu]);

  return (
    <div
      id="kt_aside"
      className={clsx("aside", classes.aside.join(" "))}
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
      ref={asideRef}
    >
      {/* begin::Brand */}
      <div className="aside-logo flex-column-auto" id="kt_aside_logo">
        {/* begin::Logo */}
        <Link to="/home">
          <img
            alt="Logo"
            className="logo"
            src={toAbsoluteUrl(
              `/media/clients/${company.dominio}/logo/logo_white.png`
            )}
          />
        </Link>
        {/* end::Logo */}

        {/* begin::Aside toggler */}
        {/* {config.aside.minimize && (
          <div
            id="kt_aside_toggle"
            className="btn btn-icon w-auto px-0 btn-active-color-secondary aside-toggle"
            data-kt-toggle="true"
            data-kt-toggle-state="active"
            data-kt-toggle-target="body"
            data-kt-toggle-name="aside-minimize"
            onClick={minimize}
          >
            <CaretDoubleRight
              size={24}
              className={"svg-icon-1 rotate-180"}
              weight="regular"
            />
          </div>
        )} */}
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}

      {/* begin::Aside menu */}
      <div className="aside-menu flex-column-fluid">
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}
    </div>
  );
};

export { AsideDefault };
