import { SpinnerGap } from "phosphor-react";
import classes from "./Loading.module.css";

type Props = {
  onlyIcon?: boolean;
};

const Loading: React.FC<Props> = ({ onlyIcon }) => {
  return (
    <div className="fs-6 fw-semibold text-center p-6">
      <SpinnerGap className={classes.icon} size={30} />{" "}
      {!onlyIcon ? "A carregar..." : ""}
    </div>
  );
};

export default Loading;
