import React, { Suspense, useContext, useEffect } from "react";
import PublicRoutes from "./routes/Public";
import { useDispatch, useSelector } from "react-redux";

import { LayoutContext } from "./components/_metronic/layout/core/LayoutProvider";
import FullPageLoading from "./components/UI/Loading/FullPageLoading";
import { userActions } from "./store/user";
import { menusActions } from "./store/menus";

import { getInfo } from "./axios/requests/users";
import { getUserMenus } from "./axios/requests/menus";
import { AppDispatch } from "./store/store";
import { getCompanyDetails } from "./axios/requests/company";
import { companyActions } from "./store/company";
import axios from "./axios/config";

const App: React.FC = () => {
  const layoutProvider = useContext(LayoutContext);

  const dispatch = useDispatch<AppDispatch>();

  const user = useSelector((state: any) => state.user);
  const menus = useSelector((state: any) => state.menus.menus);
  const company = useSelector((state: any) => state.company);

  useEffect(() => {
    let subdominio = window.location.host.split(".")[0];

    getCompanyDetails(subdominio)
      .then((res) => {
        axios.defaults.headers.common["company"] = subdominio;
        dispatch(companyActions.save(res.data));
      })
      .catch(() => {
        dispatch(companyActions.save({ dominio: "" }));
        layoutProvider.setAppLoading(false);
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const storedToken = localStorage.getItem("token");

    if (!storedToken) {
      layoutProvider.setAppLoading(false);
    } else if (!user.access_token) {
      dispatch(userActions.login({ access_token: storedToken }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (company.dominio) {
      if (user.access_token && !user.id) {
        getInfo(user.access_token)
          .then((res: any) => {
            if (!layoutProvider.loading) {
              layoutProvider.setAppLoading(true);
            }
            dispatch(userActions.saveInfo(res.data));
          })
          .catch((err) => {
            if (err.response.status === 401) {
              localStorage.removeItem("token");
              layoutProvider.setAppLoading(false);
            }
          });
      } else if (user.id) {
        getUserMenus(user.access_token).then((res: any) => {
          dispatch(menusActions.save({ menus: res.data }));
        });
      }
    }
  }, [company, user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (company.dominio && menus) {
      layoutProvider.setAppLoading(false);
    }
  }, [company, menus]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Suspense fallback={<div></div>}>
      {/* <Theme /> */}
      {!layoutProvider.loading ? <PublicRoutes /> : <FullPageLoading />}
    </Suspense>
  );
};

export default App;
