import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import classes from "./Questionario.module.css";
import { toAbsoluteUrl } from "../../../../components/_metronic/helpers";
import {
  createEvaluationsAnswers,
  getEvaluationAnswers,
  getEvaluationByHash,
  getEvaluationsType,
  getFormByEvaluationType,
} from "../../../../axios/requests/trainings/list/list";
import { GetCustomDate } from "../../../../utilities/utilities";
import Loading from "../../../../components/UI/Loading/Loading";
import { CheckCircle, Warning } from "phosphor-react";
import Radio from "../../../../components/UI/Radio/Radio";
import LoadingButton from "../../../../components/UI/LoadingButton/LoadingButton";
import TextArea from "../../../../components/UI/TextArea/TextArea";
import moment from "moment";
import { toast } from "react-toastify";
import Input from "../../../../components/UI/Input/Input";
import Checkbox from "../../../../components/UI/Checkbox/Checkbox";

const TrainingForms: React.FC = () => {
  const user = useSelector((state: any) => state.user);
  const token = useSelector((state: any) => state.user.access_token);
  const company = useSelector((state: any) => state.company);

  const [isLoading, setIsLoading] = useState(false);

  const [isSumbmitted, setIsSumbmitted] = useState(false);
  const [isOutdated, setIsOutdated] = useState(false);

  const hash = useParams().hash;

  const [groups, setGroups] = useState<any>([]);

  const [answers, setAnswers] = useState<any>();

  const [evaluation, setEvaluation] = useState<any>(null);

  const [type, setType] = useState<any>({});

  function updateAnswer(value: any, param: any) {
    let copy = JSON.parse(JSON.stringify(answers));

    copy.find((answer: any) => answer.id == param).resposta = value;

    setAnswers(copy);
  }

  function getForm() {
    setIsLoading(true);
    getFormByEvaluationType(
      token,
      evaluation.id_tipo_avaliacao,
      user.language
    ).then((res) => {
      if (res.status === 200) {
        getEvaluationAnswers(token, evaluation.id).then((res2) => {
          if (res2.status === 200) {
            let answers: any = [];
            let forms = res.data;
            let respostas = res2.data;
            forms.forEach((grupo: any) => {
              grupo.formacoes_questionario_grupos_items.forEach((item: any) => {
                respostas.find((obj: any) => obj.id_item == item.id)
                  ? answers.push({
                      id: item.id,
                      resposta: respostas.find(
                        (obj: any) => obj.id_item == item.id
                      ).resposta,
                    })
                  : answers.push({
                      id: item.id,
                      resposta: "",
                    });
              });
            });
            setIsLoading(false);

            setAnswers(answers);
            setGroups(res.data);
          }
        });
      }
    });
  }

  function getEvaluation() {
    getEvaluationByHash(token, hash).then((res) => {
      if (res.status === 200) {
        if (res.data.data_limite < moment().format("YYYY-MM-DD")) {
          setIsOutdated(true);
        }
        setEvaluation(res.data);
      }
    });
  }

  function getType() {
    getEvaluationsType(token, evaluation.id_tipo_avaliacao).then((res) => {
      if (res.status === 200) {
        setType(res.data);
      }
    });
  }

  function sendAnswers(avaliacao: any, respostas: any) {
    setIsLoading(true);
    createEvaluationsAnswers(token, avaliacao.id, respostas)
      .then((res) => {
        if (res.status === 200) {
          toast(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            type: "success",
            theme: "colored",
          });

          setIsLoading(false);
          setIsSumbmitted(true);
        }
      })
      .catch((err) => {
        toast(err.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          type: "error",
          theme: "colored",
        });
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getEvaluation();
  }, []);

  useEffect(() => {
    if (evaluation && evaluation.id_tipo_avaliacao) {
      getType();
    }
  }, [evaluation?.id_tipo_avaliacao]);

  useEffect(() => {
    if (evaluation) {
      getForm();
    }
  }, [evaluation]);

  return (
    <div>
      <form className={`${classes.formMainDiv} col-12`}>
        <div className={`${classes.header} col-12`}>
          <label className="col-2" />
          <img
            src={toAbsoluteUrl(
              `/media/clients/${company.dominio}/logo/logo_white.png`
            )}
            height={"70%"}
          />
        </div>
        {!isSumbmitted && !isOutdated && evaluation ? (
          <div className="col-9 d-flex flex-column justify-content-center">
            <div className="my-5 align-self-center">
              <h1>{type.titulo}</h1>
            </div>
            <span
              className="mb-5"
              dangerouslySetInnerHTML={{
                __html: type.descricao,
              }}
            ></span>

            <div className="d-flex bg-secondary p-5 rounded col-12 mb-6">
              <div className="d-flex flex-column">
                {(evaluation.id_tipo_avaliacao == 1 ||
                  evaluation.id_tipo_avaliacao == 2) && (
                  <span className="d-flex mb-2 mt-2">
                    <h6 className="mr-2">Colaborador: </h6>
                    {evaluation.nome_colaborador}
                  </span>
                )}
                {(evaluation.id_tipo_avaliacao == 1 ||
                  evaluation.id_tipo_avaliacao == 2) && (
                  <span className="d-flex mb-2">
                    <h6 className="mr-2">Setor: </h6>
                    {evaluation.nome_setor}
                  </span>
                )}
                <span className="d-flex mb-2">
                  <h6 className="mr-2">Formação: </h6>
                  {evaluation.nome_curso}
                </span>
                <span className="d-flex mb-2">
                  <h6 className="mr-2">Formador: </h6>
                  {evaluation.turma.formacoes_turmas_formadores.map(
                    (obj: any) => {
                      return obj?.formacoes_empresas_formacao_formadore?.nome;
                    }
                  )}
                </span>
                <span className="d-flex">
                  <h6 className="mr-2">Período: </h6>
                  {GetCustomDate(
                    evaluation.turma.data_inicio,
                    evaluation.turma.data_fim
                  )}
                </span>
              </div>
            </div>
            {groups && (
              <>
                {/* GROUP BODY */}
                {groups &&
                  groups.map((group: any, groupIndex: number) => {
                    return (
                      <div
                        key={`group${group.id}_${groupIndex}`}
                        id={`bodyGroup${group.id}_${groupIndex}`}
                        className="col-12 pl-0 mb-6"
                      >
                        <h4 className="text-primary">{group.grupo}</h4>

                        {/* Para Radio Buttons */}
                        {group.formacoes_questionario_grupos_items.map(
                          (itemGroup: any, itemGroupIndex: any) => {
                            if (itemGroup.tipo_componente == "radio") {
                              return (
                                <div
                                  key={`group${group.id}_item_group_${itemGroup.id}_${itemGroupIndex}`}
                                  style={{
                                    display: "flex",
                                    flexFlow: "column",
                                  }}
                                  className="mb-6"
                                >
                                  {" "}
                                  <p className="col-12 mb-1">
                                    {evaluation.id_tipo_avaliacao != 3
                                      ? itemGroup.posicao +
                                        "." +
                                        " " +
                                        itemGroup.titulo
                                      : itemGroup.titulo}
                                  </p>
                                  <div
                                    className={`d-flex flex-row mb-0 order-${itemGroup.posicao}`}
                                  >
                                    {itemGroup.formacoes_questionarios_items_opcoes.map(
                                      (item: any, itemIndex: any) => {
                                        return (
                                          <Radio
                                            id={`group${group.id}${itemGroup.id}_item${item.id}_radio_${itemIndex}`}
                                            key={`group${group.id}${itemGroup.id}_item${item.id}_radio_${itemIndex}`}
                                            name={`group${group.id}item${item.id}_${itemGroup.id}_${itemIndex}`}
                                            title={item.opcao}
                                            state={
                                              answers.find(
                                                (obj: any) =>
                                                  obj.id == itemGroup.id
                                              ).resposta
                                            }
                                            setState={updateAnswer}
                                            param={itemGroup.id}
                                            value={item.id}
                                            color="primary"
                                            disabled={
                                              evaluation.data_resposta
                                                ? true
                                                : false
                                            }
                                          />
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              );
                            }
                            if (itemGroup.tipo_componente == "checkbox") {
                              return (
                                <div
                                  key={`group${group.id}_item_group_${itemGroup.id}_${itemGroupIndex}`}
                                  style={{
                                    display: "flex",
                                    flexFlow: "column",
                                  }}
                                >
                                  {" "}
                                  <p className="col-12 mb-1">
                                    {evaluation.id_tipo_avaliacao != 3
                                      ? itemGroup.posicao +
                                        "." +
                                        " " +
                                        itemGroup.titulo
                                      : itemGroup.titulo}
                                  </p>
                                  <div
                                    className={`d-flex flex-row mb-7 order-${itemGroup.posicao}`}
                                  >
                                    {itemGroup.formacoes_questionarios_items_opcoes.map(
                                      (item: any, itemIndex: any) => {
                                        return (
                                          <Checkbox
                                            id={`group${group.id}${itemGroup.id}_item${item.id}_radio_${itemIndex}`}
                                            key={`group${group.id}${itemGroup.id}_item${item.id}_radio_${itemIndex}`}
                                            title={item.opcao}
                                            state={answers
                                              .find(
                                                (obj: any) =>
                                                  obj.id == itemGroup.id
                                              )
                                              .resposta.split(",")
                                              .find(
                                                (obj: any) => obj == item.id
                                              )}
                                            stateUpdate={(value: any) => {
                                              let copy = JSON.parse(
                                                JSON.stringify(answers)
                                              );
                                              let array =
                                                copy.find(
                                                  (answer: any) =>
                                                    answer.id == itemGroup.id
                                                ).resposta != ""
                                                  ? copy
                                                      .find(
                                                        (answer: any) =>
                                                          answer.id ==
                                                          itemGroup.id
                                                      )
                                                      .resposta.split(",")
                                                  : [];

                                              if (
                                                array.find(
                                                  (obj: any) => obj == value
                                                )
                                              ) {
                                                array = array.filter(
                                                  (obj: any) => obj != value
                                                );
                                              } else {
                                                array.push(value);
                                              }
                                              copy.find(
                                                (answer: any) =>
                                                  answer.id == itemGroup.id
                                              ).resposta = array.toString();
                                              setAnswers(copy);
                                            }}
                                            param={itemGroup.id}
                                            value={item.id}
                                            uncheckValue={item.id}
                                            disable={
                                              evaluation.data_resposta
                                                ? true
                                                : false
                                            }
                                            space="mr-6"
                                          />
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              );
                            } else if (itemGroup.tipo_componente == "input") {
                              return (
                                <div className="mb-6">
                                  <p className="mb-1">
                                    {evaluation.id_tipo_avaliacao != 3
                                      ? itemGroup.posicao +
                                        "." +
                                        " " +
                                        itemGroup.titulo
                                      : itemGroup.titulo}
                                  </p>
                                  <Input
                                    key={`group${group.id}text${itemGroupIndex}`}
                                    id={`group${group.id}text${itemGroupIndex}`}
                                    state={
                                      answers.find(
                                        (obj: any) => obj.id == itemGroup.id
                                      ).resposta
                                    }
                                    stateUpdate={updateAnswer}
                                    param={itemGroup.id}
                                    disabled={
                                      evaluation.data_resposta ? true : false
                                    }
                                    type={"text"}
                                  />
                                </div>
                              );
                            } else if (
                              itemGroup.tipo_componente == "textarea"
                            ) {
                              return (
                                <div className="mb-6">
                                  <p className="mb-1">
                                    {evaluation.id_tipo_avaliacao != 3
                                      ? itemGroup.posicao +
                                        "." +
                                        " " +
                                        itemGroup.titulo
                                      : itemGroup.titulo}
                                  </p>
                                  <TextArea
                                    key={`group${group.id}text${itemGroupIndex}`}
                                    id={`group${group.id}text${itemGroupIndex}`}
                                    state={
                                      answers.find(
                                        (obj: any) => obj.id == itemGroup.id
                                      ).resposta
                                    }
                                    stateUpdate={updateAnswer}
                                    param={itemGroup.id}
                                    disabled={
                                      evaluation.data_resposta ? true : false
                                    }
                                  />
                                </div>
                              );
                            }
                          }
                        )}
                      </div>
                    );
                  })}
                {/* END GROUP BODY */}
                {!evaluation.data_resposta ? (
                  <div className="d-flex justify-content-center">
                    <LoadingButton
                      title="Submeter"
                      loading={isLoading}
                      style="btn btn-primary mb-10"
                      onClick={() => {
                        sendAnswers(evaluation, answers);
                      }}
                    />
                  </div>
                ) : null}
              </>
            )}
          </div>
        ) : null}
      </form>
      {isLoading && <Loading onlyIcon />}
      {isOutdated && (
        <div className="d-flex justify-content-center mt-10 flex-column">
          <Warning size={40} className={"align-self-center mb-3"} />
          <h2 className="text-center">
            A data para preencher o questionário foi ultrapassada.
          </h2>
        </div>
      )}

      {isSumbmitted && (
        <div className="d-flex justify-content-center mt-10 flex-column">
          <CheckCircle size={40} className={"align-self-center mb-3"} />
          <h2 className="text-center">
            O questionário foi submetido com sucesso
          </h2>
        </div>
      )}
    </div>
  );
};

export default TrainingForms;
