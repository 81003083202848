import axios from "../config";

export const getInfo = (access_token: string) => {
  return axios.get("/users", {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const getAllUsers = (
  access_token: string,
  limit: any,
  offset: any,
  order: any,
  search: string,
  active: number,
  sector?: any
) => {
  return axios.get(
    `/users/all?offset=${offset}&limit=${limit}&order=${order[0]}&order=${
      order[1]
    }&active=${active}&search=${search}&sector=${sector ? sector : ""}`,
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
};

export const getUserById = (access_token: string, id: number) => {
  return axios.get(`/users/${id}`, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const updateUser = (
  access_token: string,
  user: any,
  userId?: number
) => {
  return axios.put(`/users${userId ? `?userId=${userId}` : ""}`, user, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const createUser = (access_token: string, user: any) => {
  return axios.post("/users", user, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const patchLanguage = (access_token: string, language: any) => {
  return axios.patch(
    "/users/language",
    { idioma: language },
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
};

export const getAllUsersTypes = (access_token: string, active?: any) => {
  return axios.get(`/users/types${active ? `?ativo=${active}` : ""}`, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const verifyUsers = (access_token: string, classId: any, body: any) => {
  return axios.post(`/trainings/users/${classId}`, body, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};
