import React, { Suspense, useMemo } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { WithChildren } from "../components/_metronic/helpers";
import TopBarProgress from "react-topbar-progress-indicator";
import Error404 from "../components/Errors/404";

//PAGES
const Home = React.lazy(() => import("../pages/Home/Home"));

const Maintenance_Users = React.lazy(
  () => import("../pages/Company/Employees/Employees")
);

const Maintenance_User = React.lazy(
  () => import("../pages/Company/Employees/Employee")
);

const Evaluations = React.lazy(
  () => import("../pages/Evaluations/Evaluations/Evaluations")
);

const ExtraordinaryEvaluations = React.lazy(
  () => import("../pages/Evaluations/Extraordinary/List")
);

const CreateExtraordinaryEvaluation = React.lazy(
  () => import("../pages/Evaluations/Extraordinary/Create")
);

const EvaluationForm = React.lazy(
  () => import("../pages/Evaluations/Evaluations/EvaluationForm")
);

const SelfEvaluations = React.lazy(
  () => import("../pages/Evaluations/SelfEvaluation/SelfEvaluations")
);

const EvaluationSchedule = React.lazy(
  () => import("../pages/Evaluations/Schedule/Schedules")
);

const EvaluationScheduleDetail = React.lazy(
  () => import("../pages/Evaluations/Schedule/Schedule")
);

const EvaluationsMaintenance = React.lazy(
  () => import("../pages/Evaluations/Maintenance/Maintenance")
);

const EvaluationsFeedbackConfirmation = React.lazy(
  () => import("../pages/Evaluations/FeedbackConfirmation/FeedbackConfirmation")
);

const EvaluationsHistoric = React.lazy(
  () => import("../pages/Evaluations/History/History")
);

const SectorEvaluations = React.lazy(
  () => import("../pages/Evaluations/SectorEvaluations/SectorEvaluations")
);

const SectorEvaluationForm = React.lazy(
  () => import("../pages/Evaluations/SectorEvaluations/SectorEvaluationForm")
);

//HISTORY
const EmployeeEvaluation = React.lazy(
  () =>
    import(
      "../pages/Evaluations/History/Cards/Collaborators/EmployeeEvaluation"
    )
);

const SectorsEvaluation = React.lazy(
  () => import("../pages/Evaluations/History/Cards/Sectors/SectorsEvaluation")
);
const MySectorEvaluation = React.lazy(
  () => import("../pages/Evaluations/History/Cards/MySector/MySectorEvaluation")
);

const HierarchicalLevels = React.lazy(
  () => import("../pages/Company/HierarchicalLevels/HierarchicalLevels")
);

const Configuration = React.lazy(
  () => import("../pages/Evaluations/Configuration/Configuration")
);

const Sectors = React.lazy(() => import("../pages/Company/Sectors/AllTabs"));

const Logs = React.lazy(() => import("../pages/Company/Logs/Logs"));

const Profile = React.lazy(() => import("../pages/Profile/Profile"));

const Access = React.lazy(() => import("../pages/Company/Access/Access"));

const MenuGroups = React.lazy(
  () => import("../pages/Company/MenuGroups/MenuGroups")
);

//REPORTS
const EvaluationsReports = React.lazy(
  () => import("../pages/Evaluations/Reports/Reports")
);

const ReportsEvolution = React.lazy(
  () => import("../pages/Evaluations/Reports/Employees/AvaliacaoEvolucao")
);

const ReportsState = React.lazy(
  () => import("../pages/Evaluations/Reports/Employees/EstadoAvaliacoes")
);

const ReportsGlobal = React.lazy(
  () => import("../pages/Evaluations/Reports/Global/Global")
);

const ReportsExportGlobal = React.lazy(
  () => import("../pages/Evaluations/Reports/Global/ExportarGlobal")
);

const ReportsFeedback = React.lazy(
  () => import("../pages/Evaluations/Reports/Employees/ConfirmacaoFeedback")
);

const ReportsAggregated = React.lazy(
  () => import("../pages/Evaluations/Reports/Employees/ConsultaAgregada")
);

const ReportsTraining = React.lazy(
  () => import("../pages/Evaluations/Reports/Employees/PropostaFormacao")
);

const ReportsSectorEvolution = React.lazy(
  () => import("../pages/Evaluations/Reports/Sectors/AvaliacaoEvolucao")
);

const ReportsSectorState = React.lazy(
  () => import("../pages/Evaluations/Reports/Sectors/EstadoAvaliacoes")
);

const ReportsSkills = React.lazy(
  () => import("../pages/Evaluations/Reports/Employees/AvaliacaoCompetencias")
);

const ReportsQuestions = React.lazy(
  () => import("../pages/Evaluations/Reports/Global/ExportarPerguntas")
);

//ROUTES
const Rotas = React.lazy(() => import("../pages/Company/Routes/Routes"));

const Notifications = React.lazy(
  () => import("../pages/Company/Notifications/Notifications")
);

//ROLES
const Roles = React.lazy(
  () => import("../pages/Company/RolesLevels/RolesLevels")
);

//TRAININGS
const TrainingsMaintenance = React.lazy(
  () => import("../pages/Trainings/Maintenance/Maintenance")
);
const TrainingsList = React.lazy(() => import("../pages/Trainings/List/List"));

const Training = React.lazy(() => import("../pages/Trainings/List/Training"));

const PlansList = React.lazy(
  () => import("../pages/Trainings/Plans/PlansList")
);
const TrainingPlan = React.lazy(
  () => import("../pages/Trainings/Plans/TrainingPlan")
);
const TrainingSummary = React.lazy(
  () => import("../pages/Trainings/Summary/Summary")
);

const TrainingReports = React.lazy(
  () => import("../pages/Trainings/Reports/Relatorios")
);

const TrainingReportGeneral = React.lazy(
  () => import("../pages/Trainings/Reports/Content/RelatorioGeral")
);

const TrainingHours = React.lazy(
  () => import("../pages/Trainings/Reports/Content/HorasFormacao")
);

const Facilities = React.lazy(
  () => import("../pages/Company/Facilities/Facilities")
);

//EXITS
const ExitsMaintenance = React.lazy(
  () => import("../pages/ExitInterviews/Maintenance/Maintenance")
);

const ExitsList = React.lazy(() => import("../pages/ExitInterviews/List/List"));

const Exit = React.lazy(
  () => import("../pages/ExitInterviews/List/ExitInterview")
);

const ExitReports = React.lazy(
  () => import("../pages/ExitInterviews/Reports/Reports")
);

const ReasonsReport = React.lazy(
  () => import("../pages/ExitInterviews/Reports/Content/GeralReport")
);

//RECRUTAMENTO
const RecruitmentMaintenance = React.lazy(
  () => import("../pages/Recrutamento/Manutencao")
);

const RecruitmenOpenings = React.lazy(
  () => import("../pages/Recrutamento/Vagas/Vagas")
);

const RecruitmenOpeningsDetalhe = React.lazy(
  () => import("../pages/Recrutamento/Vagas/VagaDetalhe")
);

const RecruitmenCandidates = React.lazy(
  () => import("../pages/Recrutamento/Candidatos/Candidatos")
);

const RecruitmenCandidate = React.lazy(
  () => import("../pages/Recrutamento/Candidatos/CandidatoDetalhe")
);

//PORTAL DO COLABORADOR
const EmployeePortalPersonalData = React.lazy(
  () => import("../pages/EmployeePortal/MyData/MyData")
);

const EmployeePortalMaintenance = React.lazy(
  () => import("../pages/EmployeePortal/Maintenance/Maintenance")
);

const PrivateRoutes: React.FC = () => {
  const menus = useSelector((state: any) => state.menus.menus);

  const menusCopy = useMemo(() => {
    if (menus) {
      let copy = [...menus];

      for (let i = 0; i < copy.length; i++) {
        if (copy[i].children) {
          copy.push(...copy[i].children);
        }
      }

      return copy;
    } else {
      return [];
    }
  }, [menus]);

  function verifyAccess(id: number, Element: any) {
    return (
      <SuspensedView>
        {menusCopy.some((menu: any) => menu.id == id) ? (
          <Element />
        ) : (
          <Error404 />
        )}
      </SuspensedView>
    );
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          <SuspensedView>
            <Home />
          </SuspensedView>
        }
      />
      <Route
        path="/home"
        element={
          <SuspensedView>
            <Home />
          </SuspensedView>
        }
      />
      <Route
        path="/profile"
        element={
          <SuspensedView>
            <Profile />
          </SuspensedView>
        }
      />
      <Route
        path="/company/employees"
        element={verifyAccess(2, Maintenance_Users)}
      />
      <Route
        path="/company/employees/:id"
        element={verifyAccess(2, Maintenance_User)}
      />
      <Route path="/evaluations/list" element={verifyAccess(4, Evaluations)} />
      <Route
        path="/evaluations/list/:id"
        element={verifyAccess(4, EvaluationForm)}
      />
      <Route
        path="/evaluations/extraordinary"
        element={verifyAccess(4, ExtraordinaryEvaluations)}
      />
      <Route
        path="/evaluations/extraordinary/new"
        element={verifyAccess(49, CreateExtraordinaryEvaluation)}
      />
      <Route
        path="/evaluations/sector-evaluations"
        element={verifyAccess(4, SectorEvaluations)}
      />
      <Route
        path="/evaluations/sector-evaluations/:id"
        element={verifyAccess(4, SectorEvaluationForm)}
      />
      <Route
        path="/evaluations/self-evaluations"
        element={verifyAccess(17, SelfEvaluations)}
      />
      <Route
        path="/evaluations/self-evaluations/:id"
        element={verifyAccess(17, EvaluationForm)}
      />
      <Route
        path="/evaluations/schedules"
        element={verifyAccess(18, EvaluationSchedule)}
      />
      <Route
        path="/evaluations/schedules/:id"
        element={verifyAccess(18, EvaluationScheduleDetail)}
      />
      <Route
        path="/evaluations/reports"
        element={verifyAccess(5, EvaluationsReports)}
      />
      <Route
        path="/evaluations/reports/evolution"
        element={verifyAccess(10, ReportsEvolution)}
      />
      <Route
        path="/evaluations/reports/state"
        element={verifyAccess(12, ReportsState)}
      />
      <Route
        path="/evaluations/reports/global"
        element={verifyAccess(16, ReportsGlobal)}
      />
      <Route
        path="/evaluations/reports/export-global"
        element={verifyAccess(32, ReportsExportGlobal)}
      />
      <Route
        path="/evaluations/reports/feedback"
        element={verifyAccess(14, ReportsFeedback)}
      />
      <Route
        path="/evaluations/reports/aggregated"
        element={verifyAccess(11, ReportsAggregated)}
      />
      <Route
        path="/evaluations/reports/training"
        element={verifyAccess(15, ReportsTraining)}
      />
      <Route
        path="/evaluations/reports/sector-evolution"
        element={verifyAccess(52, ReportsSectorEvolution)}
      />
      <Route
        path="/evaluations/reports/sector-state"
        element={verifyAccess(53, ReportsSectorState)}
      />
      <Route
        path="/evaluations/reports/skills"
        element={verifyAccess(65, ReportsSkills)}
      />
      <Route
        path="/evaluations/reports/questions"
        element={verifyAccess(67, ReportsQuestions)}
      />
      <Route
        path="/evaluations/maintenance"
        element={verifyAccess(6, EvaluationsMaintenance)}
      />
      <Route
        path="/evaluations/historic"
        element={verifyAccess(7, EvaluationsHistoric)}
      />
      <Route
        path="/evaluations/feedback-confirmation"
        element={verifyAccess(19, EvaluationsFeedbackConfirmation)}
      />
      <Route
        path="/evaluations/historic/consult"
        element={verifyAccess(7, EmployeeEvaluation)}
      />
      <Route
        path="/company/levels"
        element={verifyAccess(22, HierarchicalLevels)}
      />
      <Route
        path="/evaluations/configurations"
        element={verifyAccess(23, Configuration)}
      />
      <Route path="/company/sectors" element={verifyAccess(8, Sectors)} />
      <Route path="/company/logs" element={verifyAccess(28, Logs)} />
      <Route path="/company/permissions" element={verifyAccess(24, Access)} />
      <Route
        path="/company/group-menus"
        element={verifyAccess(25, MenuGroups)}
      />
      <Route path="/company/routes" element={verifyAccess(26, Rotas)} />
      <Route
        path="/company/notifications"
        element={verifyAccess(27, Notifications)}
      />
      <Route path="/company/roles" element={verifyAccess(21, Roles)} />
      <Route
        path="/company/facilities"
        element={verifyAccess(54, Facilities)}
      />
      <Route
        path="/trainings/maintenance"
        element={verifyAccess(30, TrainingsMaintenance)}
      />
      <Route path="/trainings/list" element={verifyAccess(37, TrainingsList)} />
      <Route path="/trainings/list/new" element={verifyAccess(37, Training)} />
      <Route path="/trainings/list/:id" element={verifyAccess(37, Training)} />
      <Route path="/trainings/plan" element={verifyAccess(40, PlansList)} />
      <Route
        path="/trainings/plan/new"
        element={verifyAccess(40, TrainingPlan)}
      />
      <Route
        path="/trainings/plan/:id"
        element={verifyAccess(40, TrainingPlan)}
      />
      <Route
        path="/trainings/summary"
        element={verifyAccess(38, TrainingSummary)}
      />
      <Route
        path="/trainings/reports"
        element={verifyAccess(39, TrainingReports)}
      />
      <Route
        path="/trainings/reports/hours"
        element={verifyAccess(56, TrainingHours)}
      />
      <Route
        path="/trainings/reports/general"
        element={verifyAccess(55, TrainingReportGeneral)}
      />
      <Route
        path="/evaluations/historic/sectors-evaluation"
        element={verifyAccess(7, SectorsEvaluation)}
      />
      <Route
        path="/evaluations/historic/sector-evaluations"
        element={verifyAccess(7, MySectorEvaluation)}
      />
      <Route
        path="/interviews/maintenance"
        element={verifyAccess(46, ExitsMaintenance)}
      />
      <Route path="/interviews/list" element={verifyAccess(48, ExitsList)} />
      <Route path="/interviews/list/:id" element={verifyAccess(48, Exit)} />
      <Route path="/interviews/list/new" element={verifyAccess(48, Exit)} />
      <Route
        path="/interviews/reports"
        element={verifyAccess(62, ExitReports)}
      />
      <Route
        path="/interviews/reports/reasons"
        element={verifyAccess(63, ReasonsReport)}
      />
      <Route
        path="/recruitment/maintenance"
        element={verifyAccess(45, RecruitmentMaintenance)}
      />
      <Route
        path="/recruitment/openings"
        element={verifyAccess(43, RecruitmenOpenings)}
      />
      <Route
        path="/recruitment/openings/new"
        element={verifyAccess(43, RecruitmenOpeningsDetalhe)}
      />
      <Route
        path="/recruitment/openings/:id"
        element={verifyAccess(43, RecruitmenOpeningsDetalhe)}
      />
      <Route
        path="/recruitment/candidates"
        element={verifyAccess(44, RecruitmenCandidates)}
      />
      <Route
        path="/recruitment/candidates/new"
        element={verifyAccess(44, RecruitmenCandidate)}
      />
      <Route
        path="/recruitment/candidates/:id"
        element={verifyAccess(44, RecruitmenCandidate)}
      />
      <Route
        path="/portal/my-data"
        element={verifyAccess(58, EmployeePortalPersonalData)}
      />
      <Route
        path="/portal/maintenance"
        element={verifyAccess(66, EmployeePortalMaintenance)}
      />
      <Route path="/login" element={<Navigate to="/home" />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

const SuspensedView: React.FC<WithChildren> = ({ children }) => {
  TopBarProgress.config({
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export default PrivateRoutes;
