import axios from "../config";

export const getUserMenus = (access_token: string) => {
  return axios.get(`/menus`, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const getAllUserMenus = (access_token: string, user:any) => {
  return axios.get(`/menus/${user}`, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const getAllMenus = (access_token: string) => {
  return axios.get("/menus/all", {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const updateAllMenuUser = (access_token: string,user:any,body:any) => {
  return axios.post(`/menus/user?user=${user}`,body, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};