import { FC } from "react";
import { useLang } from "./Metronici18n";
import { IntlProvider } from "react-intl";

import ptMessages from "./keys/pt.json";
import enMessages from "./keys/en.json";
import { WithChildren } from "../helpers";

const allMessages = {
  EN: enMessages,
  PT: ptMessages,
};

const I18nProvider: FC<WithChildren> = ({ children }) => {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export { I18nProvider };
