import clsx from "clsx";

type Props = {
  id: any;
  name: string;
  state: any;
  setState: any;
  title?: string;
  param?: any;
  value: any;
  size?: string;
  color?: string;
  disabled?: boolean;
};

const Radio: React.FC<Props> = ({
  id,
  name,
  title,
  state,
  setState,
  param,
  value,
  size,
  color,
  disabled,
}) => {
  return (
    <div
      className={clsx("pointer", size, title && "d-flex align-items-center")}
    >
      <input
        id={id}
        name={name}
        type="radio"
        onChange={(e) => {
          if (e.target.checked) {
            if (param) {
              setState(value, param);
            } else {
              setState(value);
            }
          }
        }}
        checked={state == value}
        className={`form-check-input ${color ? color : "green"} pointer`}
        disabled={disabled}
      />
      {title ? (
        <label
          htmlFor={id}
          className="form-check form-check-custom form-check-solid pointer ml-2 mr-4"
        >
          {title}
        </label>
      ) : null}
    </div>
  );
};

export default Radio;
