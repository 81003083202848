import axios from "../config";

export const getCompanyDetails = (dominio: string) => {
  return axios.get(`/company/${dominio}`);
};

export const getUserCompanys = (access_token: string) => {
  return axios.get("/company", {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const getAllCompanies = (access_token: string) => {
  return axios.get("/company/all", {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};
