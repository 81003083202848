import { configureStore } from "@reduxjs/toolkit";

//SLICES
import menusSlice from "./menus";
import userSlice from "./user";
import companySlice from "./company";

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    menus: menusSlice.reducer,
    company: companySlice.reducer,
  },
});

export default store;

export type AppDispatch = typeof store.dispatch;
