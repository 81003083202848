import clsx from "clsx";
import { Info } from "phosphor-react";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  type: string;
  state: any;
  stateUpdate: any;
  id?: any;
  title?: string;
  required?: boolean;
  placeholder?: string;
  param?: any;
  size?: string;
  disabled?: boolean;
  space?: any;
  topTag?: boolean;
  labelSize?: any;
  center?: boolean;
  info?: any;
};

const Input: React.FC<Props> = ({
  type,
  state,
  stateUpdate,
  id,
  title,
  required,
  placeholder,
  param,
  size,
  disabled,
  space,
  center,
  info,
}) => {
  return (
    <div className={clsx(size, space)}>
      {title ? (
        <div className="d-flex">
          <label
            className={clsx("col-form-label", required && "required")}
            htmlFor={id}
          >
            {title}
          </label>
          {info ? (
            <OverlayTrigger placement="top" overlay={<Tooltip>{info}</Tooltip>}>
              <div className="ml-1">
                <Info size={16} />
              </div>
            </OverlayTrigger>
          ) : null}
        </div>
      ) : null}
      <input
        id={id}
        type={type}
        required={required ? required : false}
        placeholder={placeholder}
        onChange={(e) => {
          if (param != null) {
            stateUpdate(e.target.value, param);
          } else {
            stateUpdate(e.target.value);
          }
        }}
        disabled={disabled}
        value={state ? state : ""}
        className={clsx("form-control", center ? "text-center" : "")}
      />
    </div>
  );
};

export default Input;
