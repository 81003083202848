import axios from "../../../config";

export function getAllTrainings(
  accessToken: string,
  data_inicio: any,
  data_fim: any,
  estado?: any,
  cursos?: any,
  empresa?: any
) {
  return axios.get(
    `/trainings?data_inicio=${data_inicio}&data_fim=${data_fim}${
      estado ? `&estado=${estado}` : ""
    }${cursos ? `&cursos=${cursos}` : ""}${
      empresa ? `&empresa=${empresa}` : ""
    }`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function createTraining(accessToken: string, body: any) {
  return axios.post(`/trainings`, body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getTrainingById(accessToken: string, id: any, turma?: any) {
  return axios.get(`/trainings/${id}${turma ? `?turma=${turma}` : ""}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getClassesFromTraining(accessToken: string, id: any) {
  return axios.get(`/trainings/classes/${id}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getClassById(accessToken: string, id: any) {
  return axios.get(`/trainings/class/${id}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getAllCalendar(accessToken: string, id?: any) {
  return axios.get(`/trainings/calendar${id ? `?id=${id}` : ""}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function createClass(accessToken: string, training: any) {
  return axios.post(
    `/trainings/classes?formacao=${training}`,
    {},
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function deleteClass(accessToken: string, turma: any) {
  return axios.patch(
    `/trainings/class/${turma}`,
    {},
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function deleteTraining(accessToken: string, training: any) {
  return axios.patch(
    `/trainings/${training}`,
    {},
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function createEvent(
  accessToken: string,
  trainingId: any,
  classId: any,
  body: any
) {
  return axios.post(
    `/trainings/calendar?formacao=${trainingId}&turma=${classId}`,
    body,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function updateTrainingEvent(
  accessToken: string,
  trainingId: any,
  classId: any,
  eventId: any,
  body: any
) {
  return axios.put(
    `/trainings/calendar/${eventId}?formacao=${trainingId}&turma=${classId}`,
    body,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function updateTraining(
  accessToken: string,
  trainingId: any,
  classId: any,
  body: any
) {
  return axios.put(`/trainings/${trainingId}?turma=${classId}`, body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getAllTrainingsEvaluationsType(accessToken: string) {
  return axios.get(`/trainings/evaluations/types`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getEvaluationsByType(
  accessToken: string,
  type: any,
  training: any,
  classId: any
) {
  return axios.get(
    `/trainings/evaluations/${classId}?tipo=${type}&formacao=${training}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function updateDeadline(
  accessToken: string,
  type: any,
  training: any,
  classId: any,
  body: any
) {
  return axios.put(
    `/trainings/evaluations/deadline/${classId}?tipo=${type}&formacao=${training}`,
    body,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function sendEmailWithEvaluationLink(accessToken: string, body: any) {
  return axios.post(`/trainings/evaluations/send-links`, body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getCollaboratorsInfoByClass(accessToken: string, classId: any) {
  return axios.get(`/trainings/evaluations/info/${classId}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getFormByEvaluationType(
  accessToken: string,
  type: any,
  language: any
) {
  return axios.get(`/trainings/evaluations/form/${type}?language=${language}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getEvaluationByHash(accessToken: string, token: any) {
  return axios.get(`/trainings/evaluations?token=${token}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getEvaluationAnswers(accessToken: string, evaluations: any) {
  return axios.get(`/trainings/evaluations/answers?avaliacoes=${evaluations}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function createEvaluationsAnswers(
  accessToken: string,
  evaluationId: any,
  body: any
) {
  return axios.post(`/trainings/evaluations/answers/${evaluationId}`, body, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function deleteEvent(accessToken: string, classId: any, eventId: any) {
  return axios.delete(`/trainings/calendar/${eventId}?turma=${classId}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function additionalMaintenance(
  accessToken: string,
  trainingId: any,
  classId: any,
  body: any
) {
  return axios.put(
    `/trainings/additional-maintenance?turma=${classId}&formacao=${trainingId}`,
    body,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function createQuit(
  accessToken: string,
  trainingId: any,
  classId: any,
  body: any
) {
  return axios.post(
    `/trainings/quit?turma=${classId}&formacao=${trainingId}`,
    body,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function sendConvocation(
  accessToken: string,
  trainingId: any,
  classId: any,
  body: any
) {
  return axios.post(
    `/trainings/convocation?turma=${classId}&formacao=${trainingId}`,
    body,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getClassConvocation(
  accessToken: string,
  trainingId: any,
  classId: any
) {
  return axios.get(
    `/trainings/convocation?turma=${classId}&formacao=${trainingId}`,
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function getEvaluationsType(accessToken: string, typeId: any) {
  return axios.get(`/trainings/evaluations/types/${typeId}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function getAllWorkTypes(accessToken: string, active?: any) {
  return axios.get(`/trainings/work-types${active ? `?ativo=${active}` : ""}`, {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  });
}

export function confirmTask(accessToken: string, id: any) {
  return axios.patch(
    `/trainings/tasks/finish/${id}`,
    {},
    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function addTaskToClass(accessToken: string, id: any, body: any) {
  return axios.post(
    `/trainings/tasks/add/${id}`,
    body,

    {
      headers: {
        authorization: `Bearer ${accessToken}`,
      },
    }
  );
}
