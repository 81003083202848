import axios from "../../config";

export const getEvaluatedCollaborators = (
  access_token: string,
  year: any,
  type: any,
  ended: any
) => {
  return axios.get(
    `/evaluations/historic?year=${year}&tipo=${type}&finalizada=${ended}`,
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
};

export const getFeedbackMethod = (access_token: string, user: any) => {
  return axios.get(`/evaluations/feedback-method?user=${user}`, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const createFeedbackMethod = (access_token: string, body: any) => {
  return axios.post(`/evaluations/feedback-method`, body, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const getCompetenciesForNeeds = (
  access_token: string,
  evaluation: any
) => {
  return axios.get(`/evaluations/competency-needs?avaliacao=${evaluation}`, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const getTrainingNeeds = (
  access_token: string,
  user: any,
  evaluation: any
) => {
  return axios.get(
    `/evaluations/training-needs?user=${user}&avaliacao=${evaluation}`,
    {
      headers: {
        authorization: `Bearer ${access_token}`,
      },
    }
  );
};

export const createTrainningNeeds = (access_token: string, body: any) => {
  return axios.post(`/evaluations/training-needs`, body, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const getFeedbackConfirmations = (access_token: string) => {
  return axios.get(`/evaluations/feedback-confirmations`, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};

export const confirmFeedback = (evaluation: any, feedback: any, body: any) => {
  return axios.put(
    `/evaluations/feedback-confirmation?avaliacao=${evaluation}&feedback=${feedback}`,
    body
  );
};

export const checkFeedbackConfirmation = (hash: any) => {
  return axios.get(`/evaluations/feedback-confirmation?hash=${hash}`);
};

export const getEvaluatedSectors = (access_token: string, year: any) => {
  return axios.get(`/evaluations/evaluated-sectors?year=${year}`, {
    headers: {
      authorization: `Bearer ${access_token}`,
    },
  });
};
